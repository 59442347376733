import request from '@/utils/request'

export function getDriverLicenceTypeById(data) {
  return request({
    url: `/api/DriverLicenceTypes/GetDriverLicenceTypeById/${data}`,
    method: 'get',
  })
}

export function getDriverLicenceTypes() {
  return request({
    url: '/api/DriverLicenceTypes/GetDriverLicenceTypes',
    method: 'get',
  })
}

export function addDriverLicenceType(data) {
  return request({
    url: '/api/DriverLicenceTypes/AddDriverLicenceType',
    method: 'post',
    data,
  })
}

export function updateDriverLicenceType(data) {
  return request({
    url: '/api/DriverLicenceTypes/UpdateDriverLicenceType',
    method: 'put',
    data,
  })
}
export function deleteDriverLicenceType(data) {
  return request({
    url: `/api/DriverLicenceTypes/DeleteDriverLicenceType?frDriverLicenseTypeId=${data}`,
    method: 'delete',
  })
}
